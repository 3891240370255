import { datadogLogs } from '@datadog/browser-logs';
import { injectJuicyScript } from '../utils/injectScript';
import { useEffect, useRef } from 'react';
import { useGetClientApplication } from '../api/atoms';
import { useNavigator } from './useNavigator';

interface JuicyInstance {
  getSessionId: () => string;
  getCompletedSessionId: (timeout: number) => string;
}

declare global {
  interface Window {
    juicyScoreApi: JuicyInstance;
  }
}

export const JUICY_SCRIPT = `${process.env.REACT_APP_JUICY_URL}&apiKey=${process.env.REACT_APP_JUICY_SCRIPT_KEY}`;

const NO_JUICY_SESSION_ID = 'No session id';

export const useJuicy = () => {
  const { navigate } = useNavigator();
  const apiApplication = useGetClientApplication();

  const postJuicyRedirection = useRef<boolean>(false);

  useEffect(() => {
    injectJuicyScript();
  }, []);

  const callJuicyScore = async (
    application_id: number | undefined,
    session_id: string | undefined = NO_JUICY_SESSION_ID,
  ) => {
    if (!application_id) return;

    try {
      if (session_id !== NO_JUICY_SESSION_ID)
        datadogLogs.logger.info('juicyscore call with sessionId - success');
      else datadogLogs.logger.info('juicyscore call with sessionId - error');

      await fetch(`${process.env.REACT_APP_ANALYTICS_API}/juicyscore`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          application_id,
          session_id,
        }),
      });
    } catch {}

    return;
  };

  const generateJuicyApplicationSession = async ({
    nextRoute,
  }: {
    nextRoute?: string;
  } = {}) => {
    try {
      let juicySessionId: string | undefined;

      const newApplication = await apiApplication.fetch({
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
          'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
        },
      });

      // In case juicy script injection fails
      setTimeout(async () => {
        if (!postJuicyRedirection.current) {
          await callJuicyScore(newApplication.id, juicySessionId);
          if (nextRoute) navigate(nextRoute);
        }
      }, 12000);

      // Full method to get the completed session id
      try {
        juicySessionId = await window.juicyScoreApi.getSessionId(); // start raw data collection
        const juicyCompleteSessionId =
          await window.juicyScoreApi.getCompletedSessionId(10000); // getting the completed session id
        if (juicyCompleteSessionId) juicySessionId = juicyCompleteSessionId;
        datadogLogs.logger.info(
          'Juicy sessions received from getSessionId + getCompletedSessionId method',
        );
      } catch (error) {
        // TODO: Remove "any" after checking logs on Prod to know why juicy script fails and which type of error returns
        if (!juicySessionId) {
          datadogLogs.logger.error(
            `juicy failed - applicationId: ${newApplication.id} - sessionId - error message: ${error.message}`,
            {},
          );
        } else {
          datadogLogs.logger.error(
            `juicy failed - applicationId: ${newApplication.id} - completedSessionId - error message: ${error.message}`,
            {},
          );
        }
      }

      await callJuicyScore(newApplication.id, juicySessionId);
    } catch {
      // Catch juicy error to hide any possible error
    } finally {
      postJuicyRedirection.current = true;
      if (nextRoute) navigate(nextRoute);
    }
  };

  return {
    generateJuicyApplicationSession,
  };
};
