import { App } from '../App';
import { Response as GetApplicationResponse } from '../api/typegen/client/application/get';
import { getClientProductNumber } from '../utils/getProductNumber';
import { Response as GetClientResponse } from '../api/typegen/client/get';
import { useCallback } from 'react';

export type CrossLoginRedirectTo = 'dashboard' | 'vivusonline';

function useCrossDomainLoginRedirect() {
  const { fetch: oneTimeTokenFetch } = App.useApi(
    'client/security/one-time-token',
    'POST',
  );
  /** Log in user in dashboard app */
  const crossDomainLoginRedirect = useCallback(
    async (to: CrossLoginRedirectTo = 'dashboard', getUrl?: boolean) => {
      try {
        const baseUrl =
          to === 'vivusonline'
            ? process.env.REACT_APP_VIVUSONLINE_URL
            : process.env.REACT_APP_DASHBOARD_URL;

        const queryString = window.location.search
          ? `&${window.location.search.substring(1)}`
          : '';

        const { oneTimeToken } = await oneTimeTokenFetch();

        const redirectURL = `${baseUrl}/one-time-token-login?token=${oneTimeToken}${queryString}`;
        if (getUrl) return redirectURL;
        else {
          sessionStorage.clear();
          window.location.assign(redirectURL);
        }
      } catch (e) {}
    },
    [oneTimeTokenFetch],
  );

  const maybeRedirectCrossDomain = useCallback(
    (client: GetClientResponse, application: GetApplicationResponse) => {
      if (client.status === 'IDENTIFIED') {
        crossDomainLoginRedirect('dashboard');
        return true;
      }

      const clientProductNumber =
        application?.status === 'OPEN'
          ? application.productNumber
          : getClientProductNumber(client?.availableProducts);

      if (clientProductNumber === '6') {
        crossDomainLoginRedirect('vivusonline');
        return true;
      }
    },
    [crossDomainLoginRedirect],
  );

  return {
    crossDomainLoginRedirect,
    maybeRedirectCrossDomain,
  };
}

export default useCrossDomainLoginRedirect;
