import * as Yup from 'yup';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { frameConfig } from '../../AppConfig';
import { getAmountAndTermFromConstraints } from '../../hooks/useApplicationSummary';
import { getClientProductNumber } from '../../utils/getProductNumber';
import { IncomeDetailsTemplate, useErrorManager } from '@4f/react';
import { injectJuicyScript } from '../../utils/injectScript';
import { R } from '../../routes';
import { sourceOfIncome } from './sourceOfIncome';
import { useAnalytics } from 'use-analytics';
import { useCallback, useEffect } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetClientEmail,
  useGetConstraints,
  useGetOffer,
} from '../../api';
import { useJuicy } from '../../hooks/useJuicy';
import { useMaybeCreateApplication } from '../../hooks/useMaybeCreateApplication';
import { useNavigator } from '../../hooks/useNavigator';
import { useRecoilValue } from 'recoil';
import { useWebcode } from '../../hooks/useWebcode';
import useIovation, { ioBlackBoxAtom } from '../../hooks/useIovation';

const ValidationSchema = Yup.object().shape({
  incomeSource: Yup.string().required(
    App.translate('validation.required_field'),
  ),
  averageMonthlyIncome: Yup.string().required(
    App.translate('validation.required_field'),
  ),
});

const sourceOfIncomeOptions = sourceOfIncome.map(({ code, value }) => ({
  name: App.translate(`incomeDetails.employment_status.${code}`),
  value,
}));

export default function IncomeDetails() {
  const { navigateContinue, navigate } = useNavigator();
  const { setError } = useErrorManager();
  const apiClientProfile = App.useApi('client/profile', 'PUT');
  const { fetch: fetchGetClient } = useGetClient();
  const apiOffer = useGetOffer();
  const apiClient = useGetClient();
  const apiGetClientEmail = useGetClientEmail();
  const apiConstraints = useGetConstraints();
  const apiPutClientApplication = App.useApi('client/application', 'PUT');
  const { fetch: fetchGetApplication } = useGetClientApplication();
  const { maybeCreateApplication } = useMaybeCreateApplication();
  const { isWebCodeRequired } = useWebcode();
  const { track } = useAnalytics();
  const { generateJuicyApplicationSession } = useJuicy();
  useIovation();
  const ioBlackBox = useRecoilValue(ioBlackBoxAtom);
  const apiAffiliateEventQualified = App.useApi(
    'affiliate-events/qualified',
    'POST',
  );

  useEffect(() => {
    injectJuicyScript();
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        await apiClientProfile.fetch({
          body: {
            employmentStatus: data.incomeSource,
            monthlyIncome: data.averageMonthlyIncome,
            registrationPhase: 'INCOME',
          },
        });
        let queryParameters = apiOffer.request?.queryParameters;
        if (queryParameters === undefined) {
          const constraints = await apiConstraints.fetch({
            pathParameters: {
              productNumber: getClientProductNumber(
                apiClient.data?.availableProducts,
              ),
            },
          });
          queryParameters = getAmountAndTermFromConstraints(constraints);
        }
        if (queryParameters !== undefined) {
          await apiOffer.fetch({
            pathParameters: {
              productNumber: getClientProductNumber(
                apiClient.data?.availableProducts,
              ),
            },
            queryParameters,
          });
        }

        await maybeCreateApplication();

        // Update apiGetClient data
        const client = await fetchGetClient();
        const email = await apiGetClientEmail.fetch();

        track('identify', { client, email: email.email });

        // Update apiGetApplicaiton data
        const application = await fetchGetApplication({
          headers: {
            Accept: 'application/vnd.4finance.web.v1.hal+json',
            'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
          },
        });

        track('identify', { application });

        if (application.resolution === 'REJECTED') {
          navigate(R.FirstLoan_Rejected);
          return;
        }

        // Webcode already not required, confirm application and continue to identification process
        if (!(await isWebCodeRequired())) {
          await apiPutClientApplication.fetch({
            body: {
              ioBlackBox,
            },
          });

          if (client.isRegisteredByAffiliate) {
            await apiAffiliateEventQualified.fetch();
          }

          await generateJuicyApplicationSession({
            nextRoute: R.FirstLoan_OnlineBank_Verify,
          });
          return;
        }
        navigateContinue();
      } catch (error) {
        setError(error);
      }
    },
    [
      apiClientProfile,
      apiOffer,
      maybeCreateApplication,
      isWebCodeRequired,
      navigateContinue,
      apiConstraints,
      navigate,
      setError,
    ],
  );

  return (
    <IncomeDetailsTemplate
      {...frameConfig}
      validationSchema={ValidationSchema}
      sectionID={AppRegistrationSection.Confirmation}
      content={App.translateContent('incomeDetails')}
      incomeSourceSelectOptions={sourceOfIncomeOptions}
      averageMonthlyIncomeSelectOptions={[
        { value: '00', name: '< 400 €' },
        { value: '450', name: '400 – 499 €' },
        { value: '600', name: '500 – 699 €' },
        { value: '800', name: '700 – 899 €' },
        { value: '1000', name: '900 – 1099 €' },
        { value: '1200', name: '1100 – 1399 €' },
        { value: '1600', name: '> 1400 €' },
      ]}
      onSubmit={handleSubmit}
    />
  );
}
