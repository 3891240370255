import { App } from '../../App';
import { injectJuicyScript } from '../../utils/injectScript';
import { ioBlackBoxAtom } from '../../hooks/useIovation';
import { useEffect, useState } from 'react';
import { useFetched } from '@4f/react';
import { useGetClient, useGetClientApplication } from '../../api';
import { useJuicy } from '../../hooks/useJuicy';
import { useRecoilValue } from 'recoil';
import { useResolutionResolverPhoneVerification } from './useResolutionResolverPhoneVerification';
import { useSeon } from '../../hooks/useSeon';
import RegistrationLoading from '../RegistrationLoading/RegistrationLoading';
import useAffiliateInfo from '../../hooks/useAffiliateInfo';
import useCrossDomainLoginRedirect from '../../hooks/useCrossDomainLoginRedirect';

export default function PhoneVerificationWaiting() {
  const { affiliateInfo } = useAffiliateInfo();
  const { sendSeonFraudInfo } = useSeon();
  const apiGetClient = useFetched(useGetClient());
  const ioBlackBox = useRecoilValue(ioBlackBoxAtom);
  const [attempts, setAttempts] = useState(1);
  const { crossDomainLoginRedirect } = useCrossDomainLoginRedirect();
  const { generateJuicyApplicationSession } = useJuicy();

  const apiGetClientApplication = useFetched(
    useGetClientApplication(),
    {},
    { authenticated: true, skipError: true },
  );
  const apiPutClientApplication = App.useApi('client/application', 'PUT');
  const apiAffiliateEventQualified = App.useApi(
    'affiliate-events/qualified',
    'POST',
  );
  const { fetch: fetchUpdateAffiliate } = App.useApi(
    'client/affiliate',
    'POST',
  );

  // useEffect to throw affiliates calls
  useEffect(() => {
    injectJuicyScript();
    const affiliateCalls = async () => {
      try {
        if (affiliateInfo.provider) {
          await sendSeonFraudInfo();

          await fetchUpdateAffiliate({
            body: affiliateInfo,
          });
        }

        if (apiGetClient.data?.isRegisteredByAffiliate) {
          await apiAffiliateEventQualified.fetch();
        }
      } catch {}
    };
    affiliateCalls();
  }, [affiliateInfo.provider, apiGetClient.data?.isRegisteredByAffiliate]);

  // useffect to confirm the application
  useEffect(() => {
    const confirmationCall = async () => {
      if (!apiGetClientApplication.data?.confirmed) {
        try {
          await apiPutClientApplication.fetch({
            body: {
              ioBlackBox,
            },
          });
          await generateJuicyApplicationSession();
        } catch {
          if (attempts > 3) {
            // Force re-login
            crossDomainLoginRedirect('dashboard', false);
          } else {
            setTimeout(() => setAttempts(attempts + 1), 1000);
          }
        }
      }
    };

    confirmationCall();
  }, [attempts, apiGetClientApplication.data?.confirmed]);

  useResolutionResolverPhoneVerification();

  return <RegistrationLoading />;
}
