import { Response as ApplicationResponse } from '../../../api/typegen/client/application/get';
import { Response as ApplicationStatusResponse } from '../../../api/typegen/client/application/check-status/get';
import { datadogLogs } from '@datadog/browser-logs';
import { isNotProduction } from '../../../utils/isNotProduction';
import { R, Rv2 } from '../../../routes';
import { useAnalytics } from 'use-analytics';
import { useEffect, useState } from 'react';
import {
  useGetClientApplication,
  useGetClientApplicationCheckStatus,
} from '../../../api';
import { useNavigateOrigin } from '../../../hooks/useNavigateOrigin';
import { useNavigator } from '../../../hooks/useNavigator';
import { usePolling } from '../../../hooks/usePolling';
import useAuth from '../../../hooks/useAuth';
import useNavigateAB from '../../../hooks/useNavigateAB';
import useTink from '../../../hooks/useTink';

const POLLING_INTERVAL = 5000;

export default function useResolutionResolverOnlineBank() {
  const { navigate } = useNavigator();
  const { navigateAB } = useNavigateAB();
  const { navigateOrigin } = useNavigateOrigin();
  const { fetch: fetchGetApplication } = useGetClientApplication();
  const { logout } = useAuth();
  const { track } = useAnalytics();
  const { isCurrentPathnameV2 } = useNavigateAB();
  const { handleTinkV2Error, getAndSendTinkV2Data } = useTink();
  const { fetch: fetchGetClientApplicationCheckStatus } =
    useGetClientApplicationCheckStatus();

  const [application, setApplication] = useState<ApplicationResponse | null>(
    null,
  );
  const [applicationStatus, setApplicationStatus] =
    useState<ApplicationStatusResponse | null>(null);
  const applicationPolling = usePolling(
    () =>
      fetchGetApplication({
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
          'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
        },
      }),
    (response: ApplicationResponse) => setApplication(response),
  );
  const clientApplicationCheckStatusPolling = usePolling(
    fetchGetClientApplicationCheckStatus,
    (response: ApplicationStatusResponse) => setApplicationStatus(response),
  );

  /**
   * Effects
   */
  useEffect(() => {
    clientApplicationCheckStatusPolling.startPolling(POLLING_INTERVAL);

    return () => clientApplicationCheckStatusPolling.stopPolling();
  }, []);

  useEffect(() => {
    (function handleCanPollApplication() {
      if (applicationStatus?.completed !== true || applicationPolling.isEnabled)
        return;

      clientApplicationCheckStatusPolling.stopPolling();
      setTimeout(
        () => {
          applicationPolling.startPolling(POLLING_INTERVAL);
        },
        // Note: Hack to avoid Tink Extension's mock race condition
        isNotProduction() ? 15000 : 0,
      );
    })();
  }, [
    clientApplicationCheckStatusPolling.pollingAttempts,
    applicationStatus?.completed,
    applicationPolling.isEnabled,
  ]);

  useEffect(() => {
    (function handleApplicationResolution() {
      if (!application || !application?.resolution) return;

      applicationPolling.stopPolling();
      handleNavigateByApplicationResolution(application);
    })();
  }, [application]);

  useEffect(() => {
    if (!isCurrentPathnameV2) return;

    handleTinkV2Error();
    getAndSendTinkV2Data();
  }, []);

  /**
   * Functions
   */
  async function handleNavigateByApplicationResolution(
    application: ApplicationResponse,
  ) {
    const { resolution, resolutionDetail } = application;

    track('identify', {
      application,
    });

    // Before change this switch. Please, check this -> https://wiki.4finance.net/display/FD/Flow+outcome+mapping
    switch (resolution) {
      case 'APPROVED':
        return navigateAB({
          a: R.FirstLoan_OnlineBank_Approved,
          b: Rv2.FirstLoan_OnlineBank_Approved_V2,
        });

      case 'MANUAL':
        return navigateAB({
          a: R.FirstLoan_OnlineBank_DocumentsRequired,
          b: Rv2.FirstLoan_OnlineBank_DocumentsRequired_V2,
        });

      case 'REJECTED':
        // To reproduce this error: tink extension -> APPROVED + APPROVED + 631
        if (resolutionDetail === 'NEW_OFFER') {
          return navigate(R.best_offer);
        }
        if (
          resolutionDetail &&
          [
            'REJECTED_BY_THIRD_PARTY',
            'INSTANTOR_TOO_MANY_ACCOUNTS',
            'INSTANTOR_ID_MISMATCH',
          ].includes(resolutionDetail)
        ) {
          return navigateAB({
            a: R.FirstLoan_OnlineBank_RejectedByTink,
            b: Rv2.FirstLoan_OnlineBank_RejectedByTink_V2,
          });
        }
        if (
          resolutionDetail &&
          [
            'INVALID_PERSONAL_DATA',
            'LOW_IOVATION_SCORE',
            'RISKY_TELEPHONE',
          ].includes(resolutionDetail)
        ) {
          await logout();
          return navigateOrigin();
        }

        return navigateAB({
          a: R.FirstLoan_Rejected,
          b: Rv2.FirstLoan_Rejected_V2,
        });

      default:
        // Note: Invalid state, user sees spinner indefinetly
        datadogLogs.logger.error('User ended up in invalid state.', {
          application,
        });
        return;
    }
  }
}
