import { Response as ApplicationResponse } from '../../api/typegen/client/application/get';
import { Response as ApplicationStatusResponse } from '../../api/typegen/client/application/check-status/get';
import { R } from '../../routes';
import { useEffect, useState } from 'react';
import {
  useGetClientApplication,
  useGetClientApplicationCheckStatus,
} from '../../api';
import { useNavigator } from '../../hooks/useNavigator';
import { usePolling } from '../../hooks/usePolling';
import { useSeon } from '../../hooks/useSeon';

const POLLING_INTERVAL = 5000;

export function useResolutionResolverPhoneVerification() {
  const { fetch: fetchGetApplication } = useGetClientApplication();
  const { fetch: fetchGetClientApplicationCheckStatus } =
    useGetClientApplicationCheckStatus();
  const { sendSeonFraudInfo } = useSeon();
  const { navigate } = useNavigator();

  const [application, setApplication] = useState<ApplicationResponse | null>(
    null,
  );
  const [applicationStatus, setApplicationStatus] =
    useState<ApplicationStatusResponse | null>(null);
  const applicationPolling = usePolling(
    () =>
      fetchGetApplication({
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
          'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
        },
      }),
    (response: ApplicationResponse) => {
      setApplication(response);
    },
  );
  const clientApplicationCheckStatusPolling = usePolling(
    fetchGetClientApplicationCheckStatus,
    (response: ApplicationStatusResponse) => setApplicationStatus(response),
  );

  /**
   * Effects
   */
  useEffect(() => {
    clientApplicationCheckStatusPolling.startPolling(POLLING_INTERVAL);

    return () => clientApplicationCheckStatusPolling.stopPolling();
  }, []);

  useEffect(() => {
    (function handleCanPollApplication() {
      if (applicationStatus?.completed !== true || applicationPolling.isEnabled)
        return;

      clientApplicationCheckStatusPolling.stopPolling();
      applicationPolling.startPolling(POLLING_INTERVAL);
    })();
  }, [
    clientApplicationCheckStatusPolling.pollingAttempts,
    applicationStatus?.completed,
    applicationPolling.isEnabled,
  ]);

  useEffect(() => {
    (function handleApplicationResolution() {
      if (!application) return;

      applicationPolling.stopPolling();
      handleNavigateByApplicationResolution(application);
    })();
  }, [application?.resolution]);

  /**
   * Functions
   */
  async function handleNavigateByApplicationResolution(
    application: ApplicationResponse,
  ) {
    const nextRoute =
      application.resolution === 'REJECTED'
        ? R.FirstLoan_Rejected
        : R.FirstLoan_OnlineBank_Verify;

    if (application.resolution !== 'REJECTED') {
      try {
        await sendSeonFraudInfo();
      } catch {}
    }
    navigate(nextRoute);
  }
}
