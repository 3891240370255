import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';

import { Response as GetApplication } from '../../api/typegen/client/application/get';
import { Response as GetClient } from '../../api/typegen/client/get';
import { Response as GetOffer } from '../../api/typegen/application/first-loan-offer/product-number/{productNumber}/get';
import { R } from '../../routes';
import { useCallback, useEffect, useState } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetConstraints,
  useGetOffer,
} from '../../api/atoms';
import { useLegalTerms } from '../../hooks/useLegalTerms';
import { useSearchParams } from 'react-router-dom';
import { WelcomeTemplate, WelcomeTemplateNoApplication } from '@4f/react';

import { useMaybeCreateApplication } from '../../hooks/useMaybeCreateApplication';
import { useNavigator } from '../../hooks/useNavigator';

import { debounce } from 'lodash';
import { frameConfig } from '../../AppConfig';
import { getClientProductNumber } from '../../utils/getProductNumber';
import { useRecoilValue } from 'recoil';
import { useWebcode } from '../../hooks/useWebcode';
import { ValidationError } from '../../api';
import CreditLimitWarningBox from '../../components/CreditLimitWarningBox/CreditLimitWarningBox';
import Loader from '../../components/Loader/Loader';
import useIovation, { ioBlackBoxAtom } from '../../hooks/useIovation';

type ApplicationType = 'new' | 'opened';

export default function Welcome() {
  const { data: offerData, fetch: fetchOffer } = useGetOffer();
  const [, setSearchParams] = useSearchParams();
  const { navigate } = useNavigator();
  const { data: clientData, fetch: fetchGetClient } = useGetClient();
  const { data: applicationData, fetch: fetchGetApplication } =
    useGetClientApplication();
  const { data: constraintsData, fetch: fetchConstraints } =
    useGetConstraints();
  const { fetch: fetchPutClientApplicaton } = App.useApi(
    'client/application',
    'PUT',
  );
  const apiAffiliateEventQualified = App.useApi(
    'affiliate-events/qualified',
    'POST',
  );

  const { isWebCodeRequired } = useWebcode();
  const { maybeCreateApplication } = useMaybeCreateApplication();
  const { getLegalTerms } = useLegalTerms();

  const [applicationType, setApplicationType] =
    useState<ApplicationType | null>(null);
  const [amount, setAmount] = useState(App.config.fallbackAmount);
  const [term, setTerm] = useState(App.config.fallbackTerm);
  const [warningBoxVisible, setWarningBoxVisible] = useState(false);

  const ioBlackBox = useRecoilValue(ioBlackBoxAtom);

  useIovation();

  useEffect(() => {
    fetchGetApplication({
      headers: {
        Accept: 'application/vnd.4finance.web.v1.hal+json',
        'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
      },
    }).then(
      ({
        resolution,
        status,
        amount: AmountApplication,
        term: termApplication,
      }) => {
        if (!status || status === 'CLOSED') {
          setApplicationType('new');
        } else {
          setApplicationType('opened');
        }

        // Term can be invalid if the user has productNumber 8 and the application term is 61. (The application would be rejected or expired).
        const isTermValid =
          termApplication && termApplication > 6 && termApplication < 31;

        const invalidApplicationResolution = ['CANCELLED', 'REJECTED'];
        const validResolution =
          !resolution || !invalidApplicationResolution.includes(resolution);

        const nextAmount =
          validResolution && AmountApplication ? AmountApplication : amount;
        const nextTerm =
          validResolution && termApplication && isTermValid
            ? termApplication
            : term;

        if (nextAmount !== amount) {
          setAmount(nextAmount);
        }

        if (nextTerm !== term) {
          setTerm(nextTerm);
        }

        fetchOffer({
          pathParameters: {
            productNumber: getClientProductNumber(
              clientData?.availableProducts,
            ),
          },
          queryParameters: {
            amount: nextAmount,
            term: nextTerm,
          },
        });

        fetchConstraints({
          pathParameters: {
            productNumber: getClientProductNumber(
              clientData?.availableProducts,
            ),
          },
          queryParameters: {
            amount: nextAmount,
            term: nextTerm,
          },
        });
      },
    );

    fetchGetClient().then((res) => {
      if (!res) return;
      setSearchParams(`n=${res?.number}`);
    });
  }, [setSearchParams]);

  const debouncedFetchOffer = useCallback(
    debounce(async (amount, term) => {
      fetchOffer({
        pathParameters: { productNumber: App.config.productNumber },
        queryParameters: {
          amount: amount,
          term: term,
        },
      });
    }, 500),
    [],
  );

  const handleChange = (key: string, value: number) => {
    if (key === 'amount') {
      setAmount(() => {
        debouncedFetchOffer(value, term);
        return value;
      });
    }
    if (key === 'days') {
      setTerm(() => {
        debouncedFetchOffer(amount, value);
        return value;
      });
    }
  };

  const handleSumbitExistingApplication = useCallback(
    async ({
      application,
      client,
    }: {
      client: GetClient;
      application: GetApplication;
    }) => {
      const {
        bankAccount,
        hasAttachments,
        firstName,
        lastName,
        dateOfBirth,
        actualAddress,
        monthlyIncome,
      } = client;
      // If client has opened applicaiton, we should already know the name so this shouldn't be needed

      if (!firstName || !lastName || !dateOfBirth) {
        navigate(R.FirstLoan_PersonalData);
        return;
      }

      if (!actualAddress?.fullAddress) {
        navigate(R.FirstLoan_Address);
        return;
      }

      if (!monthlyIncome) {
        navigate(R.FirstLoan_Income);
        return;
      }

      if (await isWebCodeRequired()) {
        navigate(R.FirstLoan_PhoneVerification);
        return;
      }

      if (client.status === 'IDENTIFIED' || (hasAttachments && bankAccount)) {
        navigate(R.FirstLoan_Manual_Upload_Pending);
        return;
      }

      if (!application.confirmed) {
        navigate(R.FirstLoan_PhoneVerification_Check);
        return;
      } else if (client?.isRegisteredByAffiliate) {
        await apiAffiliateEventQualified.fetch();
      }

      navigate(R.FirstLoan_OnlineBank_Verify);
    },
    [navigate],
  );

  const handleSumbitNewApplication = useCallback(
    //TODO: Export CalculatorData type from lib and replace this horrifying "any"
    async ({
      data,
      client: {
        firstName,
        lastName,
        dateOfBirth,
        actualAddress,
        monthlyIncome,
        creditLimit,
      },
      offer: { newPrincipal },
    }: {
      data: any;
      client: GetClient;
      offer: GetOffer;
    }) => {
      try {
        if (!firstName || !lastName || !dateOfBirth) {
          navigate(R.FirstLoan_PersonalData);
          return;
        }

        if (newPrincipal && creditLimit && creditLimit < newPrincipal) {
          setAmount(creditLimit);
          setWarningBoxVisible(true);
          await fetchOffer({
            pathParameters: {
              productNumber: getClientProductNumber(
                clientData?.availableProducts,
              ),
            },
            queryParameters: {
              amount: creditLimit,
              term,
            },
          });
          return;
        }

        // TODO: What if user is < 21?
        await maybeCreateApplication({ amount: data.amount, term: data.term });

        if (!actualAddress?.fullAddress) {
          navigate(R.FirstLoan_Address);
          return;
        }

        if (!monthlyIncome) {
          navigate(R.FirstLoan_Income);
          return;
        }

        if (await isWebCodeRequired()) {
          navigate(R.FirstLoan_PhoneVerification);
          return;
        }

        await fetchPutClientApplicaton({
          body: {
            ioBlackBox,
          },
        });

        navigate(R.FirstLoan_OnlineBank_Verify);
      } catch (error: unknown) {
        if (error && error instanceof ValidationError) {
          let reason: string = '';

          if (typeof error.fieldErrors[0].messageTemplate === 'string') {
            reason = error.fieldErrors[0].messageTemplate
              .split('_')
              .map((word) => word.charAt(0))
              .join('');
          }

          navigate(`${R.FirstLoan_Rejected}?r=${reason ?? ''}`);
        }
      }
    },
    [maybeCreateApplication, navigate],
  );

  if (!applicationData || !clientData || !constraintsData || !offerData) {
    return <Loader />;
  }

  if (applicationType === 'new') {
    return (
      <WelcomeTemplateNoApplication
        {...frameConfig}
        hideLogout
        sectionID={AppRegistrationSection.Intro}
        hideEditButton
        content={App.translateContent('welcome')}
        component={
          warningBoxVisible && clientData.creditLimit ? (
            <CreditLimitWarningBox creditLimit={clientData.creditLimit} />
          ) : null
        }
        calculatorProps={{
          content: {
            title: App.translate('common.calculator.title'),
            confirmChanges: App.translate('common.calculator.confirmChanges'),
            openLoanInformationModal: App.translate(
              'common.calculator.openLoanInformationModal',
            ),
            amountTitle: App.translate('registration_header.title_amount'),
            daysTitle: App.translate('registration_header.title_days'),
            amountUnit: App.translate('common.currencySymbol'),
            daysUnit: App.translate('common.days'),
            titleModal: App.translate('registration_header.title_modal'),
            swiperQuantity: App.translate('common.calculator.swiperQuantity'),
            swiperTerm: App.translate('common.calculator.swiperTerm'),
          },
          getContentModal: getLegalTerms,
          onChange: handleChange,
          onSubmit: (data) =>
            handleSumbitNewApplication({
              data,
              client: clientData,
              offer: offerData,
            }),
          constraints: {
            amount: {
              max:
                warningBoxVisible || !!clientData.dateOfBirth
                  ? clientData.creditLimit ?? 300
                  : 300,
              min: constraintsData.amountInterval?.min ?? 50,
              step: constraintsData.amountInterval?.step ?? 10,
            },
            term: {
              max: constraintsData.termInterval?.max ?? 30,
              min: constraintsData.termInterval?.min ?? 7,
              step: constraintsData.termInterval?.step ?? 1,
            },
          },
          data: {
            amount,
            days: term,
          },
        }}
      />
    );
  }

  return (
    <WelcomeTemplate
      {...frameConfig}
      sectionID={AppRegistrationSection.Intro}
      onContinue={() =>
        handleSumbitExistingApplication({
          client: clientData,
          application: applicationData,
        })
      }
      content={App.translateContent('welcome')}
      tableItems={[
        {
          label: App.translate('welcome.table.row1'),
          value: `${offerData.newPrincipal}${App.translate(
            'common.currencySymbol',
          )}`,
        },
        {
          label: App.translate('welcome.table.row2'),
          value: `${offerData.totalInterest}${App.translate(
            'common.currencySymbol',
          )}`,
        },
        {
          label: App.translate('welcome.table.row3'),
          value: `${offerData.totalRepayableAmount}${App.translate(
            'common.currencySymbol',
          )}`,
        },
        {
          label: App.translate('welcome.table.row4'),
          value: offerData.firstPaymentDate,
        },
      ]}
      hideEditButton
    />
  );
}
