import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { fetchData } from '../../api/fetchData';
import { frameConfigBasic } from '../../AppConfig';
import { RequestDeniedTemplate } from '@4f/react';
import { useEffect } from 'react';
import { useGetClientApplication } from '../../api';
import { useNavigateOrigin } from '../../hooks/useNavigateOrigin';

export default function RegistrationRejected() {
  const { navigateOrigin } = useNavigateOrigin();
  const content = App.translateContent('requestDenied');
  const { fetch: fetchGetApplication } = useGetClientApplication();

  useEffect(() => {
    const checkApplication = async () => {
      const application = await fetchGetApplication({
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
          'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
        },
      });
      if (
        application?.status === 'CLOSED' &&
        application?.resolutionDetail === 'NEW_OFFER'
      ) {
        window.location.replace(`${process.env.REACT_APP_DASHBOARD_URL}/login`);
      }
    };
    checkApplication();
  }, []);

  const handleClick = () => {
    if (sessionStorage.getItem('token')) {
      try {
        fetchData('/token/invalidate', {
          method: 'delete',
        });
      } finally {
        sessionStorage.clear();
      }
    }
    navigateOrigin();
  };

  return (
    <RequestDeniedTemplate
      {...frameConfigBasic}
      hideLogout
      content={{
        ...content,
        frequentCauses: Object.values(content.frequentCauses),
      }}
      sectionID={AppRegistrationSection.Confirmation}
      onBackBtnHandler={handleClick}
    />
  );
}
