import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { BestOfferTemplate, useErrorManager } from '@4f/react';
import { frameConfig } from '../../AppConfig';
import { injectJuicyScript } from '../../utils/injectScript';
import { ioBlackBoxAtom } from '../../hooks/useIovation';
import { Rv2 } from '../../routes';
import { useAnalyticsCookies } from '../../hooks/useAnalyticsCookies';
import { useEffect } from 'react';
import {
  useGetClient,
  useGetClientApplicationProposals,
  useGetLegalTerms,
  useGetOffer,
  usePostClientApplication,
} from '../../api';
import { useJuicy } from '../../hooks/useJuicy';
import { useNavigator } from '../../hooks/useNavigator';
import { useRecoilValue } from 'recoil';
import { useSeon } from '../../hooks/useSeon';

export default function BestOffer() {
  const apiLegalTerms = useGetLegalTerms();
  const { data: client, fetch: fetchClient } = useGetClient();
  const { data: offerData, fetch: fetchOffer } = useGetOffer();
  const { fetch: fetchGetApplicationProposals } =
    useGetClientApplicationProposals();
  const { fetch: fetchPostApplication } = usePostClientApplication();
  const apiPutClientApplication = App.useApi('client/application', 'PUT');
  const ioBlackBox = useRecoilValue(ioBlackBoxAtom);
  const { navigate } = useNavigator();
  const { setError } = useErrorManager();
  const { sendSeonFraudInfo } = useSeon();
  const { generateJuicyApplicationSession } = useJuicy();
  const { sendAnalyticsCookiesIfExists } = useAnalyticsCookies();

  useEffect(() => {
    injectJuicyScript();
    const getClient = async () => {
      await fetchClient();
    };

    if (!client) {
      getClient();
    }
  }, [client]);

  useEffect(() => {
    const getProposals = async () => {
      try {
        const proposals = await fetchGetApplicationProposals();

        const amount = proposals?.bestOfferProposal?.amount;
        const term = proposals?.bestOfferProposal?.term;

        if (amount && term) {
          await fetchOffer({
            pathParameters: {
              productNumber: client?.productNumber || App.config.productNumber,
            },
            queryParameters: {
              amount: amount,
              term: term,
            },
          });
        }
      } catch (e) {
        setError(e);
      }
    };
    getProposals();
  }, [client?.productNumber]);

  const handleLegalTerms = async () => {
    const amount = offerData?.totalPrincipal;
    const term = offerData?.term;

    try {
      const data = await apiLegalTerms.fetch({
        headers: {
          Accept: 'text/html',
          'Content-Type': 'text/html',
        },
        queryParameters: {
          attachment: false,
          amount: String(amount),
          term: Number(term),
          productNumber: client?.productNumber || App.config.productNumber,
        },
      });
      return data;
    } catch (e) {
      setError(e);
    }
  };

  const handleSubmit = async () => {
    try {
      if (offerData?.totalPrincipal && offerData?.term) {
        const applicationCreated = await fetchPostApplication({
          body: {
            amount: offerData?.totalPrincipal,
            term: offerData?.term,
            productNumber: client?.productNumber,
            source: 'DESKTOP',
            ioBlackBox,
          },
        });

        await sendAnalyticsCookiesIfExists();

        if (!applicationCreated?.rejected) {
          await apiPutClientApplication.fetch({
            body: {
              ioBlackBox,
            },
          });

          try {
            await sendSeonFraudInfo();
            await generateJuicyApplicationSession({
              nextRoute: Rv2.FirstLoan_OnlineBank_Check_V2,
            });
          } catch {}
        }
      }
    } catch (e) {
      setError(e);
    } finally {
      navigate(Rv2.FirstLoan_OnlineBank_Check_V2); // Same screen as we have on tink redirection
    }
  };

  return (
    <BestOfferTemplate
      {...frameConfig}
      offer={offerData}
      // TODO: solve type - in this corner case, the response is a string
      // @ts-ignore
      onLegalTerms={handleLegalTerms}
      content={App.translateContent('bestOffer', {
        term:
          client?.productNumber !== '7'
            ? App.translate('common.days')
            : App.translate('common.months'),
      })}
      sectionID={AppRegistrationSection.Summary}
      onSubmit={handleSubmit}
    />
  );
}
