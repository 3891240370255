import { App } from '../../App';
import { btoa } from 'js-base64';
import { Loading, useErrorManager } from '@4f/react';
import { R } from '../../routes';
import { useAnalytics } from 'use-analytics';
import { useAnalyticsCookies } from '../../hooks/useAnalyticsCookies';
import { useEffect } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetClientEmail,
} from '../../api';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useMaybeNavigateByResolution } from '../../hooks/useMaybeNavigateByResolution';
import { useNavigator } from '../../hooks/useNavigator';
import useAffiliateInfo from '../../hooks/useAffiliateInfo';
import useCrossDomainLoginRedirect from '../../hooks/useCrossDomainLoginRedirect';

export default function OneTimeTokenLogin() {
  const [searchParams] = useSearchParams();
  const { fetch: oneTimeLogin } = App.useApi('token/one-time', 'POST');
  const { fetch: registrationAffiliateLogin } = App.useApi(
    'token/affiliate',
    'POST',
  );
  const { navigate, navigateContinue } = useNavigator();
  const { setError } = useErrorManager();
  const location = useLocation();
  const { fetch: fetchGetClient } = useGetClient();
  const apiClientEmail = useGetClientEmail();
  const { fetch: fetchGetApplication } = useGetClientApplication();
  const { maybeRedirectCrossDomain } = useCrossDomainLoginRedirect();
  const { maybeNavigateByResolution } = useMaybeNavigateByResolution();
  const { fetch: fetchUpdateAffiliate } = App.useApi(
    'client/affiliate',
    'POST',
  );

  const { affiliateInfo } = useAffiliateInfo();
  const { sendAnalyticsCookiesIfExists } = useAnalyticsCookies();
  const { track, reset } = useAnalytics();
  useEffect(() => {
    reset();
    const productNumber = searchParams.get('productNumber');
    if (productNumber) {
      sessionStorage.setItem('productNumberLoginDashboard', productNumber);
    }
    const token = searchParams.get('token');

    // No token
    if (!token) {
      navigate(R.Login);
      return;
    }

    async function login(queryToken: string) {
      try {
        const { username, token } = location.pathname.includes(
          R.Registration_Affiliate,
        )
          ? await registrationAffiliateLogin({
              body: {
                token: queryToken,
              },
            })
          : await oneTimeLogin({
              body: {
                oneTimeToken: queryToken,
              },
            });

        if (!username || !token) {
          navigate(R.Login);
          return;
        }

        const authToken = btoa(`${username}:${token}`);
        sessionStorage.setItem('token', authToken);

        const client = await fetchGetClient();
        const email = await apiClientEmail.fetch();

        track('identify', {
          client,
          email: email.email,
        });

        const application = await fetchGetApplication({
          headers: {
            Accept: 'application/vnd.4finance.web.v1.hal+json',
            'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
          },
        });

        await sendAnalyticsCookiesIfExists({
          applicationId: application?.id,
          clientId: client?.id,
        });

        // Redirects to either vivusonline registration or account
        if (maybeRedirectCrossDomain(client, application)) {
          return;
        }

        if (affiliateInfo.provider) {
          await fetchUpdateAffiliate({
            body: affiliateInfo,
          });
        }

        if (application.status) {
          track('identify', {
            application,
          });
        }

        if (
          application.affiliateCooperationType === 'API' &&
          application.status === 'OPEN' &&
          !application.confirmed
        ) {
          navigate(
            `${R.Affiliate_FirstLoan_Application}${location.search ?? ''}`,
          );
          return;
        }

        if (
          await maybeNavigateByResolution({
            application,
            client,
          })
        ) {
          return;
        }

        navigateContinue();
        return;
      } catch (error) {
        setError(error);
      }
    }

    if (token) login(token);
  }, [
    navigate,
    searchParams,
    setError,
    navigateContinue,
    location.pathname,
    maybeNavigateByResolution,
    maybeRedirectCrossDomain,
    location.search,
    reset,
    track,
  ]);

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
      <Loading />
    </div>
  );
}
