import { AppMap } from '../AppMap';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateNext = useCallback(() => {
    const next = AppMap.getNext(location.pathname);

    if (!next) {
      if (
        ['local', 'test', 'staging'].includes(
          process.env.REACT_APP_ENV as string,
        )
      ) {
        // eslint-disable-next-line no-console
        console.warn(
          '[useNavigator][navigateNext]: Next location is not defined for',
          location.pathname,
        );
      }

      return;
    }

    navigate(next);
  }, [location.pathname, navigate]);

  const navigatePrev = useCallback(() => {
    const prev = AppMap.getPrevious(location.pathname);
    if (!prev) {
      if (
        ['local', 'test', 'staging'].includes(
          process.env.REACT_APP_ENV as string,
        )
      ) {
        // eslint-disable-next-line no-console
        console.warn(
          `[useNavigator][navigateBack]: Previous location is not defined for ${location.pathname}`,
          location.pathname,
        );
      }

      return;
    }

    navigate(prev);
  }, [location.pathname, navigate]);

  return {
    navigate,
    navigateContinue: navigateNext,
    navigateBack: navigatePrev,
  };
};
