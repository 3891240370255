import { FetchData } from '@4f/react';
import { GenericError, ValidationError, ValidationFieldError } from './errors';
import { getProcessEnvUrl } from '../utils/getUrl';
import { R, Rv2 } from '../routes';

// Routes excluded because they manage the 401 error (custom messages and other behaviours)
const ROUTES_EXCLUDED_401_VALIDATION = [
  R.Login,
  R.FirstLoan_Confirmation,
  R.FirstLoan_OnlineBank_Verify,
  Rv2.FirstLoan_OnlineBank_Verify_V2,
] as const;

export const redirection401ToLogin = (
  message = 'errorMessage=session_expired',
) => {
  sessionStorage.clear();

  const replace =
    process.env.REACT_APP_DASHBOARD_URL &&
    process.env.REACT_APP_USE_EXTERNAL_LOGIN === 'true'
      ? `${process.env.REACT_APP_DASHBOARD_URL}/login?${message}`
      : R.Login;
  window.location.replace(replace || 'https://account.vivus.es/login');
};

export const fetchData: FetchData = async (path, customOptions = {}) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(sessionStorage.getItem('token') && {
      Authorization: `Basic ${sessionStorage.getItem('token')}`,
    }),
  };

  if (customOptions.body instanceof FormData) {
    // @ts-ignore
    delete defaultHeaders['Content-Type'];
  }

  const options = {
    ...customOptions,
    headers: {
      ...defaultHeaders,
      ...customOptions.headers,
    },
  };

  const url = `${getProcessEnvUrl('WEB_API')}${path}`;

  // const response = await fetchRetry(url, options);
  const response = await fetch(url, options);

  if (
    (response.status === 401 || response.status === 403) &&
    (window as any).Cypress
  ) {
    // Return an empty object during cypress tests when a request hasn't been intercepted in tests
    return {} as any;
  }

  const isRouteExcluded401 = ROUTES_EXCLUDED_401_VALIDATION.includes(
    window.location.pathname,
  );

  if (
    response.status === 403 ||
    (!isRouteExcluded401 && response.status === 401)
  ) {
    sessionStorage.clear();

    const { errors }: { errors: ValidationFieldError[] } =
      await response.json();

    const errorMessageParam =
      response.status === 403
        ? 'errorMessage=validation.session'
        : 'errorMessage=session_expired';

    redirection401ToLogin(errorMessageParam);

    throw new ValidationError(errors, response.status);
  }

  if (response.status === 400) {
    const { errors }: { errors: ValidationFieldError[] } =
      await response.json();
    throw new ValidationError(errors, response.status);
  }

  if (!response.ok) {
    throw new GenericError(response.status);
  }

  try {
    let data;
    if (options.headers['Content-Type'] === 'text/html') {
      data = await response.text();
    } else if (options.headers['Content-Type'] === 'application/pdf') {
      data = await response.blob();
    } else {
      data = await response.json();
    }
    return data;
  } catch {
    // If response doesn't have content return undefined;
    return undefined as any;
  }
};
