import * as Yup from 'yup';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { frameConfig } from '../../AppConfig';
import { mobilePhoneNumberValid } from '../../utils/regularExpressions';
import {
  PhoneNumberChangeForm,
  PhoneNumberChangeTemplate,
  useErrorManager,
  useFetched,
} from '@4f/react';
import { useCallback } from 'react';
import { useGetClient, useGetClientPhone } from '../../api/atoms';
import { useNavigator } from '../../hooks/useNavigator';
import { usePhoneVerificationScreenLogic } from '../PhoneVerification/usePhoneVerificationScreenLogic';

const ValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required(App.translate('validation.required_field'))
    .test('wrong_format', App.translate('validation.wrong_format'), (value) =>
      mobilePhoneNumberValid(value ?? ''),
    ),
});

export default function PhoneVerificationChangePhone() {
  const { navigateContinue } = useNavigator();
  const { setError } = useErrorManager();
  const { updateClientPhone } = usePhoneVerificationScreenLogic();
  const { fetch: fetchGetPhone } = App.useApi('client/phone', 'GET');

  const apiGetClient = useFetched(useGetClient());
  const apiGetClientPhone = useFetched(useGetClientPhone());

  const handleSubmit = useCallback(
    async (data: PhoneNumberChangeForm) => {
      if (data.phoneNumber === apiGetClientPhone.data?.mobilePhone) {
        setError('same_phone');
      } else {
        try {
          await updateClientPhone(data.phoneNumber);

          await fetchGetPhone();

          //allow to send sms with new phone number
          if (sessionStorage.getItem('lastSMSSent')) {
            localStorage.removeItem('lastSMSSent');
          }

          navigateContinue();
        } catch (error) {
          if (
            error.fieldErrors?.[0]?.messageTemplate ===
              'already_exists_in_db' &&
            error.fieldErrors?.[0]?.property === 'mobilePhone'
          ) {
            setError('phone_already_exists');
          }
        }
      }
    },
    [
      apiGetClient,
      apiGetClientPhone,
      updateClientPhone,
      navigateContinue,
      setError,
    ],
  );

  return (
    <PhoneNumberChangeTemplate
      {...frameConfig}
      validationSchema={ValidationSchema}
      sectionID={AppRegistrationSection.Identification}
      content={App.translateContent('phoneChange')}
      onSubmit={handleSubmit}
      phoneSanitizer={(value) => value.trim().replace(/\D/g, '')}
    />
  );
}
