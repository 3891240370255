import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { frameConfig } from '../../AppConfig';
import { IntroductionTemplate } from '@4f/react';
import { useNavigator } from '../../hooks/useNavigator';

const banks = [
  'Banco Cooperativo',
  'Banco Sabadell',
  'Banco Santander',
  'Bankinter',
  'CRC - Caja Rural Central',
  'Caixa Popular',
  'Caixa Rural Les Coves',
  'Caixa Vinaros',
  'CaixaBank',
  'Caja Rural',
  'Deutsche Bank',
  'EVO Banco',
  'Global Caja',
  'ING',
  'IberCaja',
  'Caja Viva',
  'CajaSiete',
  'Cajamar',
  'Cajasur',
  'Imagin',
  'Kutxabank',
  'Laboral Kutxa',
  'Openbank',
  'RuralNostra',
  'Ruralvia',
  'Unicaja',
];

export default function Introduction() {
  const { navigateContinue } = useNavigator();
  const translations = App.translateContent('introduction');

  const listSection = [
    App.translate('introduction_list_sections.point1'),
    App.translate('introduction_list_sections.point2'),
    App.translate('introduction_list_sections.point3'),
    App.translate('introduction_list_sections.point4'),
  ];

  // @ts-ignore - TODO: enable string[] values
  const contentTranslations = {
    ...translations,
    modalBankBody: {
      ...translations.modalBankBody,
      banks,
    },
    modalBankCard: {
      ...translations.modalBankCard,
      listSection,
    },
  };
  return (
    <IntroductionTemplate
      {...frameConfig}
      hideLogout
      sectionID={AppRegistrationSection.Intro}
      onSubmit={navigateContinue}
      content={contentTranslations}
      loginLink={
        process.env.REACT_APP_DASHBOARD_URL &&
        process.env.REACT_APP_USE_EXTERNAL_LOGIN === 'true'
          ? `${process.env.REACT_APP_DASHBOARD_URL}/login`
          : '/login'
      }
      youtubeVideoUrl="https://www.youtube.com/embed/0mdcVWmr0HQ"
    />
  );
}
