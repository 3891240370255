import { App } from '../../App';
import { ApprovedTemplate } from '@4f/react';
import { fetchData, useGetClient, useGetClientApplication } from '../../api';
import { frameConfigBasic } from '../../AppConfig';
import { PAYLAND_SEARCHPARAM_CONTINUE } from '../../hooks/usePayment';
import { R } from '../../routes';
import { useBankAccountEnding } from '../../hooks/useBankAccountEnding';
import { useEffect } from 'react';
import useCrossDomainLoginRedirect from '../../hooks/useCrossDomainLoginRedirect';

export default function Approved() {
  const { data: client, fetch: fetchGetClient } = useGetClient();
  const { fetch: fetchGetClientApplication } = useGetClientApplication();
  const { crossDomainLoginRedirect } = useCrossDomainLoginRedirect();

  const onSubmit = async () => {
    const continueUrl = await crossDomainLoginRedirect('dashboard', true);
    const url_ko = `${window.location.origin}${R.payment_failed}?${PAYLAND_SEARCHPARAM_CONTINUE}=${R.FirstLoan_OnlineBank_Approved}`;
    const { payformUrl } = await fetchData('/cardpayments/store-credit-card', {
      body: JSON.stringify({
        applicationName: 'Vivus.es',
        clientNumber: client?.number,
        failureUrl: url_ko,
        successUrl: continueUrl || process.env.REACT_APP_DASHBOARD_URL || '',
        username: client?.fullName,
      }),
      headers: {
        Accept: 'application/vnd.cardpayments.v1+json',
        'Content-Type': 'application/vnd.cardpayments.v1+json',
      },
      method: 'POST',
    });
    window.location.assign(payformUrl);
  };

  useEffect(() => {
    fetchGetClient();
    fetchGetClientApplication();
  }, []);

  const bankAccountEnding = useBankAccountEnding();

  return (
    <ApprovedTemplate
      {...frameConfigBasic}
      footerLayout="default"
      sectionID={0}
      content={App.translateContent('approved', {
        amount: client?.openPrincipalAmount,
        last4DigitsAccount: bankAccountEnding,
      })}
      onSubmit={onSubmit}
    />
  );
}
