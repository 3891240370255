import { App } from '../../../App';
import { AppRegistrationSection } from '../../../AppRegistrationSection';
import { ErrorCodeTemplate } from '@4f/react';
import { frameConfigBasic } from '../../../AppConfig';
import { useNavigator } from '../../../hooks/useNavigator';

export default function OnlineBankError() {
  const { navigateContinue } = useNavigator();

  return (
    <ErrorCodeTemplate
      {...frameConfigBasic}
      hideLogout
      content={App.translateContent('online_bank_identification_errorCode')}
      onButtonClickHandler={navigateContinue}
      sectionID={AppRegistrationSection.Confirmation}
    />
  );
}
