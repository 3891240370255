import { App } from '../App';
/* eslint-disable prettier/prettier */
// TODO: remove all those and use those useApiGlobal there instead.
export const useGetConstraints = (key?: string) => App.useSharedApi('application/constraints/product-number/{productNumber}', 'GET', key);
export const useGetOffer = (key?: string) => App.useSharedApi('application/first-loan-offer/product-number/{productNumber}', 'GET', key);
export const usePostLeadsRegister = (key?: string) => App.useSharedApi('leads', 'POST', key);
export const useGetClient = (key?: string) => App.useSharedApi('client', 'GET', key);
export const useGetClientPhone = (key?: string) => App.useSharedApi('client/phone', 'GET', key);
export const usePutClientPhone = (key?: string) => App.useSharedApi('client/phone', 'PUT', key);
export const useGetClientApplication = (key?: string) => App.useSharedApi('client/application', 'GET', key);
export const useGetClientApplicationProposals = (key?: string) => App.useSharedApi('client/application/proposals', 'GET', key);
export const usePostClientApplication = (key?: string) => App.useSharedApi('client/application', 'POST', key);
export const useGetClientApplicationCheckStatus = (key?: string) => App.useSharedApi('client/application/check-status', 'GET', key);
export const useGetLegalTerms = (key?: string) => App.useSharedApi('first-loan-legal-terms', 'GET', key);
export const useGetClientEmail = (key?: string) => App.useSharedApi('client/email', 'GET', key);
