import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { frameConfigBasic } from '../../AppConfig';
import { RequestLoadingTemplate } from '@4f/react';
export default function RegistrationLoading() {
  return (
    <RequestLoadingTemplate
      {...frameConfigBasic}
      hideLogout
      hideMobileFooter
      content={App.translateContent('registration_loading')}
      sectionID={AppRegistrationSection.Confirmation}
    />
  );
}
