import { atom, useRecoilState } from 'recoil';
import { useEffect } from 'react';

export const ioBlackBoxAtom = atom<string | undefined>({
  key: 'ioBlackBoxAtom',
  default: undefined,
});

const useIovation = () => {
  const [ioBlackBox, setIoBlackBox] = useRecoilState(ioBlackBoxAtom);

  useEffect(() => {
    function initIoBlackBox() {
      const iovationConfig: any = {
        bbout_element_id: 'ioBlackBox',
        enable_rip: true,
        enable_flash: false,
        install_flash: false,
        loader: {
          version: 'general5',
        },
      };

      if (process.env.REACT_APP_IOVATION_SERVER_INTEGRATION !== 'true') {
        /** Loads iovation scripts from current domain proxy or from third-party domain */
        iovationConfig.loader.fp_static = false;
      }

      (window as any).IGLOO = (window as any).IGLOO || iovationConfig;

      (window as any).IGLOO.bb_callback = function (
        blackBoxString: string,
        isComplete: boolean,
      ) {
        if (isComplete) {
          setIoBlackBox(blackBoxString);
        }
      };

      const scriptNode = document.createElement('script');
      scriptNode.async = true;
      scriptNode.type = 'text/javascript';
      scriptNode.src = `${process.env.PUBLIC_URL}/js/iojs-loader.js`;

      const firstScript: HTMLScriptElement =
        document.getElementsByTagName('script')[0];

      if (firstScript && firstScript.parentNode && scriptNode) {
        firstScript.parentNode.insertBefore(scriptNode, firstScript);
      }
    }

    if (!ioBlackBox) {
      initIoBlackBox();
    }
  }, [ioBlackBox, setIoBlackBox]);
};

export default useIovation;
