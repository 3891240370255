import { App } from '../../App';
import {
  FrameCalculatorDesktop,
  FrameCalculatorMobile,
} from '../organisms/Frame/FrameCalculator';
import { getClientProductNumber } from '../../utils/getProductNumber';
import {
  RegistrationCalculatorComponentProps,
  RegistrationGlobalComponentProps,
  useErrorManager,
} from '@4f/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetConstraints,
  useGetOffer,
} from '../../api/atoms';
import { useLegalTerms } from '../../hooks/useLegalTerms';

export type OfferQueryParametersRaw = {
  amount?: string | null;
  term?: string | null;
};

export type OfferQueryParameters = {
  amount: number;
  term: number;
};

export const sanitizeOfferQueryParameters = (
  { amount, term }: OfferQueryParametersRaw,
  defaultParameters: OfferQueryParameters,
): OfferQueryParameters => ({
  amount: amount != null ? Number(amount) : defaultParameters.amount,
  term: App.config.term || term != null ? Number(term) : defaultParameters.term,
});

export const useCalculatorSharedLogic = ({
  onCalculatorClose,
}: {
  onCalculatorClose: RegistrationGlobalComponentProps['onCalculatorClose'];
}) => {
  const apiOffer = useGetOffer();
  const apiConstraints = useGetConstraints();
  const apiClient = useGetClient();
  const apiApplication = useGetClientApplication();
  const { setError } = useErrorManager();
  const { getLegalTerms } = useLegalTerms();

  const amount =
    apiOffer.data?.newPrincipal ||
    apiApplication.data?.amount ||
    apiConstraints.data?.amountInterval?.defaultValue ||
    0;

  const days =
    apiOffer.data?.term ||
    apiApplication.data?.term ||
    App.config.term ||
    apiConstraints.data?.termInterval?.defaultValue ||
    0;

  const [dataCalculator, setDataCalculator] = useState({ amount, days });

  useEffect(() => {
    setDataCalculator({ amount, days });
  }, [amount, days]);

  const constraints = useMemo(() => {
    if (
      !apiConstraints.data?.amountInterval ||
      !apiConstraints.data.termInterval
    )
      return undefined;

    const term =
      App.config.term !== undefined
        ? {
            min: App.config.term,
            max: App.config.term,
            step: 0,
            defaultValue: App.config.term,
          }
        : {
            min: apiConstraints.data.termInterval.min,
            max: apiConstraints.data.termInterval.max,
            step: apiConstraints.data.termInterval.step,
            defaultValue: apiConstraints.data.termInterval.defaultValue,
          };

    const firstLoanAmountLimit =
      apiConstraints.data?.loanLimits?.[
        1 as keyof typeof apiConstraints.data.loanLimits
      ];

    const amount = {
      ...apiConstraints.data.amountInterval,
      max: firstLoanAmountLimit
        ? firstLoanAmountLimit
        : apiConstraints.data.termInterval.max,
      defaultValue: firstLoanAmountLimit
        ? firstLoanAmountLimit
        : apiConstraints.data.termInterval.defaultValue,
    };

    if (apiClient.data?.dateOfBirth && apiClient.data?.creditLimit)
      return {
        term,
        amount: {
          ...amount,
          max: apiClient.data.creditLimit,
          defaultValue: apiClient.data.creditLimit,
        },
      };

    return { term, amount };
  }, [
    apiClient.data?.creditLimit,
    apiClient.data?.dateOfBirth,
    apiConstraints,
  ]);

  const handleSubmit = useCallback(
    async (data: { amount: number; days: number }) => {
      try {
        await apiOffer.fetch({
          pathParameters: {
            productNumber: getClientProductNumber(
              apiClient.data?.availableProducts,
            ),
          },
          queryParameters: {
            amount: data.amount,
            term: App.config.term || data.days,
          },
        });
        onCalculatorClose();
      } catch (e) {
        setError(e);
      }
    },
    [apiOffer, onCalculatorClose, setError],
  );

  const handleLegalModal = async (
    amountChanged: number,
    termChanged: number,
  ) => {
    setDataCalculator({ amount: amountChanged, days: termChanged });
    return getLegalTerms(amountChanged, termChanged);
  };

  return {
    hideDays: App.config.term !== undefined,
    content: {
      title: App.translate('common.calculator.title'),
      amountTitle: App.translate('registration_header.title_amount'),
      daysTitle: App.translate('registration_header.title_days'),
      amountUnit: App.translate('common.currencySymbol'),
      daysUnit: App.translate('common.days'),
      titleModal: App.translate('registration_header.title_modal'),
      confirmChanges: App.translate('common.calculator.confirmChanges'),
      openLoanInformationModal: App.translate(
        'common.calculator.openLoanInformationModal',
      ),
      swiperQuantity: App.translate('common.calculator.swiperQuantity'),
      swiperTerm: App.translate('common.calculator.swiperTerm'),
    },
    constraints,
    getContentModal: handleLegalModal,
    data: apiOffer.isLoaded
      ? {
          amount: dataCalculator.amount,
          days: dataCalculator.days,
        }
      : undefined,
    onSubmit: handleSubmit,
  };
};

export const CalculatorDesktop = ({
  onCalculatorClose,
}: RegistrationCalculatorComponentProps) => {
  const calculatorProps = useCalculatorSharedLogic({
    onCalculatorClose,
  });

  return <FrameCalculatorDesktop {...calculatorProps} />;
};

export const CalculatorMobile = ({
  onCalculatorClose,
}: RegistrationCalculatorComponentProps) => {
  const calculatorProps = useCalculatorSharedLogic({
    onCalculatorClose,
  });

  return <FrameCalculatorMobile {...calculatorProps} />;
};
