export const personNameRegExp = /^[a-zA-Z\u00C0-\u017F\s]+$/gu;

export const phoneNumberRegExp = /^\+?[67][0-9]{8,8}$/;
export const phoneNumberNotAllowedRegExp = /^(6688|6686).*$/;

export const mobilePhoneNumberValid = (phone: string) =>
  phoneNumberRegExp.test(phone) && !phoneNumberNotAllowedRegExp.test(phone);

// export const emailRegExp = /^[\w-]([.\w-])*@([\w-]+)\.+[A-Za-z]{2,6}$/;
export const emailRegExp = /^[\w-]([.\w-])*@([\w-]+\.)+[A-Za-z]{2,6}$/;
export const dniRegExp = /^([X-Y-Z-x-y-z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/;
// /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/g,
export const postalRegExp = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/g;
// There are cities wih a dash in the name as: Fernán-Núñez, Fuente-Tójar y Peñarroya-Pueblonuevo
export const citiesRegExp = /^[a-zA-Z\u00C0-\u017F\s-]+$/g;

export const onlyNumbersRegExp = /^[0-9]+$/i;
export const onlyLettersRegExp = /^[a-zA-Z]+$/i;

// Password min length 8 chars, must contain at least 1 number or special char, 1 Uppercase and 1 Lowercase
export const passwordRegExp =
  /^(?!.*([A-Za-z0-9])\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/;
