import { App } from '../../App';
import { fetchData } from '../../api';
import { frameConfigBasic } from '../../AppConfig';
import { PAYLAND_SEARCHPARAM_CONTINUE } from '../../hooks/usePayment';
import {
  PaylandSuccess as PaylandSuccessTemplate,
  useErrorManager,
} from '@4f/react';
import { R } from '../../routes';
import { useEffect } from 'react';
import { useNavigator } from '../../hooks/useNavigator';

export default function PaymentSuccess() {
  const { navigate } = useNavigator();
  const { setError } = useErrorManager();
  const searchParams = new URLSearchParams(window.location.search);

  const apiAutoRepay = App.useApi(
    'client/payment-preferences/auto-repay',
    'PUT',
  );

  useEffect(() => {
    try {
      // user preferences
      apiAutoRepay.fetch({
        body: {
          autoRepay: true,
        },
      });

      // If the user has a loan, the autorepayment will be activated
      fetchData('/client/loans/latest/auto-repay', {
        body: JSON.stringify({
          autoRepay: true,
        }),
        method: 'POST',
      });
    } catch (e) {
      setError(e);
    }
  }, []);

  return (
    <PaylandSuccessTemplate
      {...frameConfigBasic}
      content={App.translateContent('paymentSuccess')}
      dataTest="continue-btn"
      sectionID={0}
      onSubmit={() =>
        navigate(
          searchParams.get(PAYLAND_SEARCHPARAM_CONTINUE) || R.FirstLoan_Welcome,
        )
      }
    />
  );
}
