import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { frameConfig } from '../../AppConfig';
import { R } from '../../routes';
import { RequestSuccessTemplate } from '@4f/react';
import { usePayment } from '../../hooks/usePayment';

export default function RegistrationTinkSuccess() {
  const { activateAutoRepay, showAutoPaymentSection } = usePayment({
    continueScreen: R.FirstLoan_Tink_Email_Pending,
  });
  return (
    <RequestSuccessTemplate
      {...frameConfig}
      onActiveBtnHandler={
        showAutoPaymentSection ? activateAutoRepay : undefined
      }
      content={App.translateContent('registration_success')}
      sectionID={AppRegistrationSection.Summary}
      hideEditButton={true}
    />
  );
}
