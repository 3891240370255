import * as Yup from 'yup';
import {
  AddressDetailsTemplate,
  TestingContext,
  useErrorManager,
} from '@4f/react';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import {
  citiesRegExp,
  onlyLettersRegExp,
  onlyNumbersRegExp,
  postalRegExp,
} from '../../utils/regularExpressions';
import { frameConfig } from '../../AppConfig';
import { useAnalytics } from 'use-analytics';
import { useContext, useEffect, useState } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetClientEmail,
} from '../../api';
import { useLocation } from 'react-router-dom';
import { useMaybeCreateApplication } from '../../hooks/useMaybeCreateApplication';
import { useNavigator } from '../../hooks/useNavigator';
import CreditLimitWarningBox from '../../components/CreditLimitWarningBox/CreditLimitWarningBox';
import provinces from '../../localizations/provinces';

const ValidationSchema = Yup.object().shape({
  address: Yup.string()
    .required(App.translate('validation.required_field'))
    .min(2, App.translate('validation.too_short')),
  number: Yup.string()
    .matches(onlyNumbersRegExp, App.translate('validation.wrong_format'))
    .max(15, App.translate('validation.too_long'))
    .required(App.translate('validation.required_direction_number')),
  floor: Yup.string().matches(
    onlyNumbersRegExp,
    App.translate('validation.wrong_format'),
  ),
  letter: Yup.string().matches(
    onlyLettersRegExp,
    App.translate('validation.wrong_format'),
  ),
  postalCode: Yup.string()
    .required(App.translate('validation.required_field'))
    .matches(postalRegExp, App.translate('validation.wrong_format')),
  province: Yup.string().required(App.translate('validation.required_field')),
  city: Yup.string()
    .matches(citiesRegExp, App.translate('validation.wrong_format'))
    .required(App.translate('validation.required_field')),
});

export default function AddressDetails() {
  const { navigateContinue } = useNavigator();
  const location = useLocation();
  const apiClientProfile = App.useApi('client/profile', 'PUT');
  const { maybeCreateApplication } = useMaybeCreateApplication();
  const { data: client } = useGetClient();
  const { data: application } = useGetClientApplication();
  const apiGetClient = useGetClient();
  const apiGetClientEmail = useGetClientEmail();
  const [validationErrors] = useState(
    location.search.includes('age-error=true')
      ? {
          _submit: [
            <CreditLimitWarningBox
              creditLimit={apiGetClient.data?.creditLimit || 0}
            />,
          ],
        }
      : {},
  );
  const { setError } = useErrorManager();
  const { track } = useAnalytics();
  const { setTestingData } = useContext(TestingContext);

  useEffect(() => {
    if (application?.id || client?.id) {
      setTestingData({
        applicationID: String(application?.id),
        clientID: String(client?.id),
      });
    }
  }, [application, client]);

  return (
    <AddressDetailsTemplate
      {...frameConfig}
      validationSchema={ValidationSchema}
      sectionID={AppRegistrationSection.Confirmation}
      provinceSelectOptions={provinces}
      content={App.translateContent('addressDetails')}
      errors={validationErrors}
      onSubmit={async (data) => {
        try {
          await apiClientProfile.fetch({
            body: {
              address: {
                postalCode: data.postalCode,
                location1: data.province,
                location2: data.city,
                location3: data.address,
                location4: data.number,
                location5: data.floor,
                location6: data.letter,
              },
              registrationPhase: 'ADDRESS',
            },
          });

          // Update apiGetClient data
          const client = await apiGetClient.fetch();
          const email = await apiGetClientEmail.fetch();

          track('identify', { client, email: email.email });

          await maybeCreateApplication();

          navigateContinue();
        } catch (error) {
          setError(error);
        }
      }}
    />
  );
}
