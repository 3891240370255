import * as Yup from 'yup';
import { App } from '../../App';
import { FirstApplicationTemplate, SectionData, useFetched } from '@4f/react';
import { formatApplicationSummary } from '../../formatters/formatApplicationSummary';
import { frameConfig } from '../../AppConfig';
import { getClientProductNumber } from '../../utils/getProductNumber';
import { R } from '../../routes';
import { AffiliateSection as Section } from './section';
import { useApplicationSummary } from '../../hooks/useApplicationSummary';
import { useEffect } from 'react';
import { useGetClient, useGetClientApplication, useGetOffer } from '../../api';
import { useNavigator } from '../../hooks/useNavigator';
import { useWebcode } from '../../hooks/useWebcode';
import es from '../../localizations/es';
import useIovation from '../../hooks/useIovation';

export const sections: SectionData[] = [
  {
    id: Section.Welcome,
    title: App.translate('affiliates.sections.welcome'),
  },
  {
    id: Section.Identification,
    title: App.translate('affiliates.sections.identification'),
  },
  {
    id: Section.Receive,
    title: App.translate('affiliates.sections.receive'),
  },
];

const validationSchema = Yup.object().shape({
  terms: Yup.bool().isTrue(App.translate('validation.agreement_required')),
});

export default function AffiliateFirstLoan() {
  const { navigateContinue, navigate } = useNavigator();
  const { fetch: fetchPutClientSettigns } = App.useApi(
    'client/settings',
    'PUT',
  );
  const { fetch: fetchPostAcceptAgreement } = App.useApi(
    'client/accept-latest-agreement-version',
    'POST',
  );
  const { isWebCodeRequired } = useWebcode();
  const apiGetClientApplication = useFetched(
    useGetClientApplication(),
    {},
    { authenticated: true, skipError: true },
  );
  const { amount, interest, repayableAmount, repaymentDate } =
    formatApplicationSummary(useApplicationSummary());
  const apiGetOffer = useGetOffer();
  const { data: clientData } = useGetClient();
  useIovation();

  // FIXME: remove this useEffect after BE fixes wrong data retrieval for Affiliates
  useEffect(() => {
    if (apiGetClientApplication.data?.amount) {
      apiGetOffer.fetch({
        queryParameters: {
          amount: apiGetClientApplication.data.amount,
          term: apiGetClientApplication.data?.term || 30,
        },
        pathParameters: {
          productNumber: getClientProductNumber(clientData?.availableProducts),
        },
      });
    }
  }, [apiGetClientApplication.data?.amount]);

  return (
    <FirstApplicationTemplate
      {...frameConfig}
      hideEditButton
      validationSchema={validationSchema}
      sectionID={Section.Welcome}
      sections={sections}
      content={{
        selectAll: App.translate('affiliates.firstapplication.selectAll'),
        title: App.translate('affiliates.firstapplication.title'),
        title_mobile: App.translate(
          'affiliates.firstapplication.title_mobile',
          {
            name: clientData?.firstName ? ` ${clientData.firstName}` : '',
          },
        ),
        sub_title: App.translate('affiliates.firstapplication.sub_title'),
        table_title: App.translate('affiliates.firstapplication.table.title'),
        table_amount_request: App.translate(
          'affiliates.firstapplication.table.amount_request',
        ),
        table_interests: App.translate(
          'affiliates.firstapplication.table.interests',
        ),
        table_return_total: App.translate(
          'affiliates.firstapplication.table.return_total',
        ),
        table_return_date: App.translate(
          'affiliates.firstapplication.table.return_date',
        ),
        table_sub_title: App.translate(
          'affiliates.firstapplication.table.sub_title',
        ),
        authorization_company: App.translate(
          'affiliates.firstapplication.authorization_company',
        ),
        authorization_me: App.translate(
          'affiliates.firstapplication.authorization_me',
        ),
        terms_condition: App.translate(
          'affiliates.firstapplication.terms_condition',
        ),
        button: App.translate('affiliates.firstapplication.button'),
        button_sub_title: App.translate(
          'affiliates.firstapplication.button_sub_title',
        ),
        faq_header: App.translate('affiliates.firstapplication.faq.header'),
        faq_items: es.affiliates.firstapplication.faq.items.map(
          (item, index) => ({
            question: App.translate(
              `affiliates.firstapplication.faq.items[${index}].question`,
            ),
            answer: App.translate(
              `affiliates.firstapplication.faq.items[${index}].answer`,
            ),
          }),
        ),
        modal_title: App.translate('affiliates.firstapplication.modal.title'),
        modal_text: App.translate('affiliates.firstapplication.modal.text'),
        modal_button: App.translate('affiliates.firstapplication.modal.button'),
      }}
      values={{
        amount: `${amount} ${App.translate('common.currencySymbol')}`,
        interest: `${interest} ${App.translate('common.currencySymbol')}`,
        repayableAmount: `${repayableAmount} ${App.translate(
          'common.currencySymbol',
        )}`,
        repaymentDate,
      }}
      onSubmit={async ({ auth_company, auth_me, terms }) => {
        if (terms) {
          await fetchPostAcceptAgreement({
            body: {},
          });
          await fetchPutClientSettigns({
            body: {
              acceptMarketingCalls: auth_me,
              acceptMarketingEmails: auth_me,
              acceptMarketingSms: auth_me,
              acceptDataSharing: auth_company,
              acceptNews: auth_company,
            },
          });
          if (await isWebCodeRequired()) {
            navigate(R.FirstLoan_PhoneVerification);
            return;
          }

          if (!apiGetClientApplication.data?.confirmed) {
            navigate(R.FirstLoan_PhoneVerification_Check);
            return;
          }

          navigateContinue();
        }
      }}
      showModal={false}
    />
  );
}
