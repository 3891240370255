import { AppRegistrationSection } from './AppRegistrationSection';
import {
  BasicFrameConfig,
  createTranslate,
  Logo,
  LogoSquare,
  RegistrationFrameConfig,
} from '@4f/react';
import {
  CalculatorDesktop,
  CalculatorMobile,
} from './components/Calculator/Calculator';
import { logout } from './actions/logout';
import { Navbar } from './components/Navbar/Navbar';
import { Panel } from './components/Panel/Panel';
import es from './localizations/es';

const translate = createTranslate(es);

export interface AppConfig {
  term?: number; // Loan duration in days - when defined, the loan duration is fixed
  age: { min: number; max: number };
  productNumber: string;
  productKind: string;
  phoneCountryCode: string;
  phoneNumber: string;
  email: string;
  businessHours: string;
  originUrl: string;
  formatting: {
    nbspSymbol: string;
    numberDelimeter: string;
    numberGroupSize: number;
    defaultDateFormat: string;
  };
  fallbackTerm: number;
  fallbackAmount: number;
  // frame
  frameConfig: RegistrationFrameConfig;
  frameConfigBasic: BasicFrameConfig;
  frameConfigBasicAffiliateFlow: BasicFrameConfig;
}

export const frameConfigBasic: BasicFrameConfig = {
  logoSquare: <LogoSquare />,
  logo: <Logo />,
  frameContent: {
    logout: translate('common.logout'),
    goBack: translate('common.goBack'),
    contactEmail: translate('common.contactEmail'),
    contactPhone: translate('common.contactPhone'),
    businessHours: translate('common.businessHours'),
    termsAndConditionsText: translate('common.termsAndConditionsText'),
    termsAndConditionsUrl: translate('common.termsAndConditionsUrl'),
  },
  onLogoutButtonClick: logout,
};

export const frameConfigBasicTink: AppConfig['frameConfig'] = {
  logoSquare: <LogoSquare />,
  logo: <Logo />,
  frameContent: {
    logout: translate('common.logout'),
    goBack: translate('common.goBack'),
    contactEmail: translate('common.contactEmail'),
    contactPhone: translate('common.contactPhone'),
    businessHours: translate('common.businessHours'),
    termsAndConditionsText: translate('common.termsAndConditionsText'),
    termsAndConditionsUrl: translate('common.termsAndConditionsUrl'),
  },
  onLogoutButtonClick: logout,
  navbar: Navbar,
};

export const frameConfigBasicAffiliateFlow: BasicFrameConfig = {
  ...frameConfigBasic,
  headerLayout: 'centered',
  footerLayout: 'default',
};

export const frameConfig: AppConfig['frameConfig'] = {
  ...frameConfigBasic,
  calculatorDesktop: CalculatorDesktop,
  calculatorMobile: CalculatorMobile,
  navbar: Navbar,
  panel: Panel,
  sections: [
    {
      id: AppRegistrationSection.Intro,
      title: translate('progress.intro'),
    },
    {
      id: AppRegistrationSection.Identification,
      title: translate('progress.identification'),
    },
    {
      id: AppRegistrationSection.Confirmation,
      title: translate('progress.confirmation'),
    },
    {
      id: AppRegistrationSection.Summary,
      title: translate('progress.summary'),
    },
  ],
};

export const config: AppConfig = {
  originUrl: process.env.REACT_APP_LANDING_PAGE_URL || 'https://www.vivus.es',
  frameConfig,
  frameConfigBasic,
  frameConfigBasicAffiliateFlow,
  age: { max: 79, min: 21 },
  productNumber: '8',
  productKind: 'VIVUS_SPL',
  phoneCountryCode: '+34',
  phoneNumber: translate('common.contactPhone'),
  email: translate('common.contactEmail'),
  businessHours: translate('common.businessHours'),
  formatting: {
    nbspSymbol: '\xa0',
    numberDelimeter: ',',
    numberGroupSize: 3,
    defaultDateFormat: 'DD.MM.YYYY',
  },
  fallbackTerm: 30,
  fallbackAmount: 300,
};
