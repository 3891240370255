import { App } from '../App';
import { getClientProductNumber } from '../utils/getProductNumber';
import { useAnalytics } from 'use-analytics';
import { useAnalyticsCookies } from './useAnalyticsCookies';
import { useCallback } from 'react';
import {
  useGetClient,
  useGetClientApplication,
  useGetOffer,
  usePostClientApplication,
} from '../api';
import { useRecoilValue } from 'recoil';
import { useSeon } from './useSeon';
import useAffiliateInfo from './useAffiliateInfo';
import useIovation, { ioBlackBoxAtom } from './useIovation';

export const useMaybeCreateApplication = () => {
  // Last offer we called
  const { request: getOfferRequest } = useGetOffer();
  const apiClient = useGetClient();
  // Last POST application we called
  const { request: postApplicationRequest, fetch: fetchPostApplication } =
    usePostClientApplication();
  const { data: getApplicationData, fetch: fetchGetApplication } =
    useGetClientApplication();
  const { sendSeonFraudInfo } = useSeon();

  const { track } = useAnalytics();
  const { sendAnalyticsCookiesIfExists } = useAnalyticsCookies();

  const ioBlackBox = useRecoilValue(ioBlackBoxAtom);

  useIovation();

  const { affiliateInfo } = useAffiliateInfo();

  const handleAnalytics = useCallback(async () => {
    try {
      const application = await fetchGetApplication({
        headers: {
          Accept: 'application/vnd.4finance.web.v1.hal+json',
          'Content-Type': 'application/vnd.4finance.web.v1.hal+json',
        },
      });

      track('identify', {
        application,
      });
    } catch {
      // Empty catch. If this fails, we don't care
    }
  }, [track]);

  const maybeCreateApplication = useCallback(
    async ({ amount, term }: { amount?: number; term?: number } = {}) => {
      // If getOfferRequest exists
      // AND getOfferRequest query params equal
      //    last call to POST application values
      //    or (if no such call was made) to last application PUT check call
      // then everything is good and no call is needed
      if (
        getOfferRequest &&
        getOfferRequest.queryParameters.amount ===
          (postApplicationRequest?.body.amount ?? getApplicationData?.amount) &&
        getOfferRequest.queryParameters.term ===
          (postApplicationRequest?.body.term ?? getApplicationData?.term) &&
        getApplicationData?.status !== 'CLOSED'
      ) {
        return;
      }

      const applicationCreated = await fetchPostApplication({
        body: {
          amount:
            amount ??
            getOfferRequest?.queryParameters.amount ??
            App.config.fallbackAmount,
          term:
            term ??
            App.config.term ??
            getOfferRequest?.queryParameters.term ??
            App.config.fallbackTerm,
          productNumber: getClientProductNumber(
            apiClient.data?.availableProducts,
          ),
          source: 'DESKTOP',
          ioBlackBox,
          affiliateInfo: affiliateInfo.provider ? affiliateInfo : undefined,
        },
      });

      await sendAnalyticsCookiesIfExists();

      if (!applicationCreated?.rejected) {
        try {
          await sendSeonFraudInfo();
        } catch {}
      }

      handleAnalytics();
    },
    [
      getOfferRequest,
      postApplicationRequest?.body.amount,
      postApplicationRequest?.body.term,
      getApplicationData?.amount,
      getApplicationData?.status,
      getApplicationData?.term,
      fetchPostApplication,
      ioBlackBox,
      handleAnalytics,
    ],
  );

  return {
    maybeCreateApplication,
  };
};
