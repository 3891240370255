/* eslint-disable no-console */
export function isEnvSwitcherEnabled(env: string) {
  return ['feqa-1', 'feqa-2', 'local', 'at1', 'demo'].includes(env);
}

export function getSelectedDevEnv(): string {
  return (
    localStorage.getItem('qaSelectedEnv') ??
    (process.env.REACT_APP_DEFAULT_API_ENV as string) ??
    'stage'
  );
}

type UrlType =
  | 'WEB_API'
  | 'COLA_URL'
  | 'FORGOTTEN_PASSWORD_LINK'
  | 'DASHBOARD_URL'
  | 'VIVUSONLINE_URL';

export function getProcessEnvUrl(url: UrlType) {
  const fallback = process.env[`REACT_APP_${url}`];

  if (!isEnvSwitcherEnabled(process.env.REACT_APP_ENV ?? '')) {
    return fallback;
  }

  const selectedDevEnv = getSelectedDevEnv().toUpperCase().replace(/-/g, '_');

  const found = process.env[`REACT_APP_${selectedDevEnv}_${url}`];

  if (!found) {
    console.error(`process.env.REACT_APP_${selectedDevEnv}_${url} not found.`);
  }

  return found ?? fallback;
}
