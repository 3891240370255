import { App } from '../App';

export function getClientProductNumber(
  availableProducts: string[] | undefined,
): string {
  // Check we have all information
  if (!availableProducts) return App.config.productNumber;

  // Apply the default productNumber; TODO -> remove this and all changes related to this properties if the testing goes well
  // if (applicationStatus === 'OPEN' || paidLoans > 0) return;

  const clientProductNumberAccordingLogin = sessionStorage.getItem(
    'productNumberLoginDashboard',
  );

  if (
    clientProductNumberAccordingLogin &&
    availableProducts.includes(clientProductNumberAccordingLogin)
  )
    return clientProductNumberAccordingLogin;

  return App.config.productNumber;
}
