/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';

// if (process.env.REACT_APP_ENV === 'production')
datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
  site: process.env.REACT_APP_DATADOG_SITE,
  service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
  env: process.env.REACT_APP_ENV,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

export default datadogLogs;
