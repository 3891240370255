import * as Yup from 'yup';
import { App } from '../../App';
import { AppRegistrationSection } from '../../AppRegistrationSection';
import { format } from 'date-fns';
import { frameConfig } from '../../AppConfig';
import { getClientProductNumber } from '../../utils/getProductNumber';
import { PersonalDataTemplate, useErrorManager } from '@4f/react';
import { personNameRegExp } from '../../utils/regularExpressions';
import { R } from '../../routes';
import { useAnalytics } from 'use-analytics';
import { useGetClient, useGetClientEmail, useGetOffer } from '../../api/atoms';
import { useMaybeCreateApplication } from '../../hooks/useMaybeCreateApplication';
import { useNavigator } from '../../hooks/useNavigator';

const ValidationSchema = Yup.object().shape({
  noSecondSurnameCheckbox: Yup.boolean(),
  firstName: Yup.string()
    .min(2, App.translate('validation.wrong_format'))
    .required(App.translate('validation.required_field'))
    .matches(personNameRegExp, App.translate('validation.wrong_format')),
  firstSurname: Yup.string()
    .min(2, App.translate('validation.wrong_format'))
    .required(App.translate('validation.required_field'))
    .matches(personNameRegExp, App.translate('validation.wrong_format')),
  secondSurname: Yup.string().when(
    'noSecondSurnameCheckbox',
    (checkboxChecked, schema) => {
      return checkboxChecked
        ? schema
        : schema
            .min(2, App.translate('validation.wrong_format'))
            .required(App.translate('validation.required_field'))
            .matches(
              personNameRegExp,
              App.translate('validation.wrong_format'),
            );
    },
  ),
  birthdate: Yup.date()
    .typeError(App.translate('validation.required_field'))
    .required(App.translate('validation.required_field')),
});

export default function PersonalData() {
  const { navigate } = useNavigator();
  const { setError } = useErrorManager();
  const apiClientProfile = App.useApi('client/profile', 'PUT');
  const apiClient = useGetClient();
  const apiClientEmail = useGetClientEmail();
  const apiGetOffer = useGetOffer();
  const { maybeCreateApplication } = useMaybeCreateApplication();
  const { track } = useAnalytics();

  return (
    <PersonalDataTemplate
      {...frameConfig}
      ageRange={{ max: 123, min: 2 }}
      validationSchema={ValidationSchema}
      sectionID={AppRegistrationSection.Confirmation}
      content={App.translateContent('personalData')}
      onSubmit={async (data) => {
        if (!data.birthdate) {
          // This shouldn't happen. Ensured by Yup
          return;
        }
        try {
          await apiClientProfile.fetch({
            body: {
              firstName: data.firstName,
              lastName: `${data.firstSurname}${
                data.noSecondSurnameCheckbox ? '' : ` ${data.secondSurname}`
              }`,
              dateOfBirth: format(data.birthdate, 'yyyy-MM-dd'),
              registrationPhase: 'PERSONAL_DATA',
            },
          });
          // Client call to update credit limit if it's needed (200 euros if user is <24 years)
          await apiClient.fetch();

          const client = await apiClient.fetch();
          const email = await apiClientEmail.fetch();
          const { creditLimit, dateOfBirth } = client;

          track('identify', { client, email: email.email });

          const creditLimitExceeded = !!(
            apiGetOffer.data?.newPrincipal &&
            dateOfBirth &&
            creditLimit &&
            creditLimit < apiGetOffer.data.newPrincipal
          );

          if (creditLimitExceeded) {
            await apiGetOffer.fetch({
              pathParameters: {
                productNumber: getClientProductNumber(
                  apiClient.data?.availableProducts,
                ),
              },
              queryParameters: {
                amount: creditLimit,
                term: App.config.term || apiGetOffer.data?.term!,
              },
            });
            await maybeCreateApplication({ amount: creditLimit });
          } else {
            await maybeCreateApplication();
          }

          const errorParam = creditLimitExceeded ? '?age-error=true' : '';
          navigate(`${R.FirstLoan_Address}${errorParam}`);
        } catch (error) {
          // TODO: show form field error instead of error notification
          setError(error);
        }
      }}
    />
  );
}
