import { App } from '../../../App';
import { frameConfigBasicAffiliateFlow } from '../../../AppConfig';
import { RepeatedLoanSuccessTemplate } from '@4f/react';
import { useBankAccountEnding } from '../../../hooks/useBankAccountEnding';
import { useNavigator } from '../../../hooks/useNavigator';

export default function RepeatedLoanSuccess() {
  const { navigateContinue } = useNavigator();
  const bankAccountEnding = useBankAccountEnding();

  return (
    <RepeatedLoanSuccessTemplate
      {...frameConfigBasicAffiliateFlow}
      bankAccount={`XXXX-XXXX-XXXX-XXXX-${bankAccountEnding}`}
      content={App.translateContent('affiliatesRepeatedLoanSuccess')}
      onSubmit={navigateContinue}
      sectionID={1}
    />
  );
}
