/* eslint-disable no-console */
import { Response as AffiliateResponse } from '../../api/typegen/affiliate/data/{provider}/get';
import { datadogLogs } from '@datadog/browser-logs';
import { fetchData, GenericError } from '../../api';
import { getBaseDomain } from '../../utils/domain';
import { R } from '../../routes';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigator } from '../../hooks/useNavigator';
import Cookies from 'js-cookie';
import RegistrationLoading from '../RegistrationLoading/RegistrationLoading';
import useSmartlook from '../../hooks/useSmartlook';

export default function AffiliateProvider() {
  const location = useLocation();
  const { navigate } = useNavigator();
  const params = useParams();
  const { error } = useSmartlook();

  useEffect(() => {
    const fetchAndRedirect = async (provider: string) => {
      try {
        const path = `/affiliate/data/${provider}${location.search}`;

        const affiliateData = (await fetchData(path, {})) as AffiliateResponse;

        datadogLogs.logger.info('Affiliate Data Requested', {
          affiliate: affiliateData,
        });

        if (!affiliateData.token) {
          const message = `Missing token for affiliate: "${provider}"`;
          error('[AffiliateProvider][fetchAndRedirect]', message);
        }

        if (!affiliateData.partner) {
          const message = `Missing partner for affiliate: "${provider}"`;
          error('[AffiliateProvider][fetchAndRedirect]', message);
        }

        const options: Cookies.CookieAttributes = {
          expires: 7,
          domain: getBaseDomain(),
        };

        Cookies.set(
          'affiliateRedirectUrl',
          affiliateData.redirectUrl || '',
          options,
        );
        Cookies.set('affiliateProvider', affiliateData.provider, options);
        Cookies.set('affiliatePartner', affiliateData.partner || '', options);
        Cookies.set('affiliateToken', affiliateData.token || '', options);
        Cookies.set('affiliateCookieCreated', String(Date.now()), options);

        if (!affiliateData.redirectUrl) {
          navigate(R.FirstLoan);
          return;
        }

        window.location.assign(affiliateData.redirectUrl);
      } catch (e: unknown) {
        datadogLogs.logger.error(
          `AffiliateProvider > fetchAndRedirect failed!`,
          {
            affiliate: {
              provider,
            },
          },
          e instanceof Error ? e : undefined,
        );

        if (e instanceof GenericError && e.status === 404) {
          console.error(`Affiliate not found: "${provider}"`);
        } else {
          console.error(`AffiliateData call failed, ${e}`);
        }
        navigate(R.FirstLoan);
      }
    };

    const providerName = params.providerName;

    if (!providerName) {
      console.error('No provider name');
      navigate(R.FirstLoan);
      return;
    }

    fetchAndRedirect(providerName);
  }, []);

  return <RegistrationLoading />;
}
